<template>
  <div class="draft" v-loading="loading">
    <div class="list-header">
      <div class="header-left">
        <div>{{$t('lang.BText33')}}</div>
        <div class="number">{{ listData.length }}/5</div>
      </div>
      <div class="header-right">
        <el-button @click="back()">{{$t('lang.BText35')}}</el-button>
        <el-button @click="clickTemplate()">{{$t('lang.BText7')}}</el-button>
        <el-button class="create" @click="createBooking()"
          >+ {{$t('lang.BText34')}}</el-button
        >
      </div>
    </div>
    <dwlist
      :url="source.booking_api.draftList"
      ref="list"
      :query-params="temQueryForm"
      :pagination="false"
      @onloadSuccess="onloadSuccess"
    >
      <el-table-column type="index" :label="$t('lang.BText98')" width="80" :index="indexMethod">
      </el-table-column>
      <el-table-column prop="DraftSummry" :label="$t('lang.BText36')" width="250">
        <template slot-scope="scope">
          <div class="port" v-if="scope.row.createDate">
            {{ scope.row.originPortName
            }}<svg-icon icon-class="right" style="color: #299be4"></svg-icon
            >{{ scope.row.destinationDischargePortName }}
          </div>
          <div v-else class="emptySlot">Empty slot</div>
        </template>
      </el-table-column>
      <el-table-column prop="Type" :label="$t('lang.BText8')" width="100">
        <template slot-scope="scope">
          <div class="FCL" v-show="scope.row.bookingType == 'FCL'">
            <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
            <div class="line"></div>
            <svg-icon class="typeIcon" icon-class="FCL"></svg-icon>
          </div>
          <div class="LCL" v-show="scope.row.bookingType == 'LCL'">
            <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
            <div class="line"></div>
            <svg-icon class="typeIcon" icon-class="LCL"></svg-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="GargoInfo" :label="$t('lang.BText37')">
        <template slot-scope="scope">
          <div v-if="scope.row.containerType">
            <div
              v-for="(item, index) in JSON.parse(scope.row.containerType)"
              :key="index"
            >
              {{ item.containerTypOne }}*{{ item.containerTypTwo }}
            </div>
          </div>
          <div class="tot">
            {{ scope.row.totalVolume }} {{ scope.row.totalVolumeUnit }}
            {{ scope.row.totalWeight }} {{ scope.row.totalWeightUnit }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="updateDate" :label="$t('lang.BText38')">
        <template slot-scope="scope">
          <span v-if="$i18n.locale == 'en_us'">{{
            zhDate_to_ehDate(scope.row.updateDate)
          }}</span
          ><span v-else>{{ scope.row.updateDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createBy" :label="$t('lang.BText39')"> </el-table-column>
      <el-table-column fixed="right" width="250">
        <template slot-scope="scope" v-if="scope.row.updateDate">
          <el-button @click="clickDelete(scope.row)" class="samll-list-info"
            >{{$t('lang.BText40')}}</el-button
          >
          <el-button class="samll-list-primary" @click="Booking(scope.row)"
            >{{$t('lang.BText35')}}</el-button
          >
        </template>
      </el-table-column>
    </dwlist>
    <dw-check-box
      :showCheckBox="showDelect"
      @Cancel="Cancel"
      @Save="Delete"
      :CancelButton="$t('lang.QText79')"
      :primaryButton="$t('lang.BText40')"
    >
      {{$t('lang.BText99')}}
    </dw-check-box>
  </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import dwCheckBox from "../../components/dwCheckBox/dwCheckBox.vue";
import utils from "../../utils/util";
export default {
  name: "bookingDraft",
  components: {
    dwlist,
    dwCheckBox,
  },
  data() {
    return {
      listData: [],
      listloading: false,
      showDelect: false,
      form: {},
      loading: false,
      temQueryForm: {},
    };
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.temQueryForm.companyId = value.companyid;
    });
  },
  computed: {
    // rightCellWidth() {
    //   return 100*()
    // },
  },
  methods: {
    indexMethod(index) {
      if (index < 10) {
        return "0" + (index + 1);
      }
      return index;
    },
     zhDate_to_ehDate(e) {
      return utils.zhDate_to_enDate(e);
    },
    query() {
      this.$refs.list.query();
    },
    createBooking() {
      this.$router.push({ name: "Booking", params: { createFlag: true } });
    },
    clickDelete(data) {
      this.showDelect = true;
      this.form.id = data.id;
    },
    onloadSuccess(data) {
      this.listData = data.rows;
    },
    Delete() {
      let url = this.source.booking_api.deleteTemplate;
      this.loading = true;
      this.showDelect = false;
      this.$ajax
        .cPost(url, this.form)
        .then((res) => {
          this.loading = false;
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.query();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    back() {
      this.$router.push({ path: "/Booking" });
    },
    Cancel() {
      this.showDelect = false;
    },
    Save() {
      this.showDelect = false;
    },
    Booking(row) {
      this.$router.push({ name: "Booking", params: { dOrTId: row.id } });
    },

    clickTemplate() {
      this.$router.push({ path: "/Booking/Template" });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.draft {
  width: 1220px;
  margin: auto;
  text-align: center;
  .FCL,
  .LCL {
    display: flex;
    align-items: center;
    .typeIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      fill: $mainColor;
      stroke: $mainColor;
    }
    .line {
      height: 15px;
      background: $mainColor;
      width: 1px;
      margin-right: 10px;
    }
  }

  .port {
    .svg-icon {
      color: $background2;
      height: 10px !important;
      width: 10px !important;
      margin-top: -5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .header-left {
      display: flex;
      color: $mainColor;
      font-size: 30px;
      .number {
        font-size: 20px;
        line-height: 35px;
        margin-left: 30px;
      }
    }
    .header-right {
      .el-button {
        height: 60px;
        font-size: 18px;
        color: $mainColor;
        border: 2px solid $mainColor;
        border-radius: 0;
        font-family: "montserratregular";
      }
      .el-button:hover {
        background-color: $background2;
        color: #fff;
        border: 2px solid $background2;
      }
      .create {
        background-color: $background2;
        color: #fff;
        border: 2px solid $background2;
        width: 210px;
        height: 60px;
        padding: 0;
        text-align: center;
      }
    }
  }

  .emptySlot {
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    color: $borderColor1 !important;
  }

  .tot {
    color: $background2;
    font-size: 12px;
  }
}
</style>